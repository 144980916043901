
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import { useI18n } from "vue-i18n/index";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import store from "@/store";
import { ElMessage } from "element-plus";
import { sha256, sha224 } from 'js-sha256';
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },
  data() {
    return {
      tourItems: "",
      currentPage: 1,
      maxPage: 1,
      data: {pendingFee:0}
    };
  },
  computed: {
    detectTourid() {
      return Number(this.$route.params.tour_id);
    },
  },
  watch: {
    detectTourid() {
      this.getTourData()
    }
  },
  mounted () {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    }
    this.getTourData()

    setTimeout(() => {
      this.getTourData();
    }, 3000);

  },
  methods: {
    setPayment() {
      let standard = document.getElementById('standard');
      let origin = 'https://openapi-hk.qfapi.com/checkstand/#/?';
      //origin = 'https://test-openapi-hk.qfapi.com/checkstand/#/?' //Test API
      let obj = {
        appcode: "2F22093B7BE5455C8664279D31746A0D", //API Code
        out_trade_no: store.state.uid+'_'+this.$route.params.tour_id+'_'+moment().format('YYYYMMDDHHmmss'),
        paysource: "remotepay_checkout",
        return_url: window.location.href,
        failed_url: window.location.href,
        notify_url: process.env.VUE_APP_API_URL + "&mode=pub&tab=payment&action=applypay&tourid=" + this.$route.params.tour_id+"&uid="+store.state.uid,
        //"&mode=pub&tab=listtour&action=tourlist&page=" + page,
        sign_type: "sha256",
        txamt: this.data.pendingFee*100,
        txcurrcd: "HKD",
        txdtm: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      };

      let api_key = "6396AF8A258842E7808CE8372E8EA3BF"; //API Key
      let params = this.paramStringify(obj, false);
      let sign = sha256(`${params}${api_key}`);
      var payLang = ''
      if (this.$route.params.lang == 'en') {
        payLang = 'en'
      }
      if (standard !== null) {
        standard.setAttribute('href', `${origin}${this.paramStringify(obj, true)}&sign=${sign}&lang=`+payLang);
      }
    },
    getTourData() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=tourapply&action=tourpay&tourid=" + this.$route.params.tour_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          this.data = response.data.data
          this.setPayment()
        });
    },
    paramStringify(json, flag) {
      let str = "";
      let keysArr = Object.keys(json);
      keysArr.sort().forEach(val => {
        if (!json[val]) return;
        str += `${val}=${flag ? encodeURIComponent(json[val]) : json[val]}&`;
      });
      return str.slice(0, -1);
    }
  },
});
