
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n/index";
//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },
  data() {
    return {
      tourItems: "",
      currentPage: 1,
      maxPage: 1,
      tourContent: "",
    };
  },
  computed: {
    tour_id() {
      return this.$route.params.tour_id;
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    tour_id () {
      if (this.tour_id && this.$route.name === "tourInfo") {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      ApiService.post(
        process.env.VUE_APP_API_URL +
          "&mode=pub&tab=info&tourid=" +
          this.tour_id +
          "&sport=" + this.$route.params.sport + "&region=" + this.$route.params.region,
        {}
      ).then((response) => {
        this.tourContent = response.data;
      });
    },
  },
});
