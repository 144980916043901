
import { defineComponent} from "vue";

import ApiService from "@/core/services/ApiService";
import axios from "axios";
import { ElMessage } from "element-plus";
import store from "@/store";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },
  data() {
    return {
      tourFormat: "",
      roundAry: [],
      points: [],
      gameAry: [],
      matches: [],
      deadlineAry: [],
      seedAry: [],
      drawTab: "",
      penaltyAry: [],
      data: [],
      deadJson: [],
      pagination: {
        rowsPerPage: 20, // current rows per page being displayed
      },
    };
  },
  watch: {
    tour_id() {
      if (this.tour_id && this.$route.matched[1].name === "draw") {
        this.getData();
      }
    },
    divi() {
      if (this.$route.name === "tourDrawInfo") {
        if (!this.$route.params.divi) {
          this.$router.replace(
            {name: 'tourDrawInfo', params: { tour_id: this.tour_id, divi: this.roundAry[0]["group_id"] }}
          );
        }
        //this.drawTab = this.divi
      }
    },
  },
  mounted() {
    if (this.tour_id || this.tourFormat) {
      this.getData();
    }
  },
  computed: {
    divi() {
      return this.$route.params.divi;
    },
    tour_id() {
      return this.$route.params.tour_id;
    },
  },
  methods: {
    quitTourClick(uid) {
      document.getElementById('close')!.click();
      
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=quitTour&quitTourUid=" + uid + "&group_id=" + this.$route.params.divi,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          if (response.data.result == "success") {
            ElMessage.success(this.$t("adminQuitTourSuccess"));
          } else {
            ElMessage.error(this.$t("error"));
          }
          document.getElementById('close')!.click();
          this.getData()
        });
        
    },
    imageLoadError(e) {
      e.target.src = "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
      //this.profileUrl = "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
    },
    getData() {
      this.tourFormat = "";
      this.seedAry = []
      ApiService.post(
        process.env.VUE_APP_API_URL +
          "&mode=pub&tab=draw&tourid=" +
          this.tour_id,
        {}
      ).then((response) => {
        this.tourFormat = response.data.format;
        // handle minislam and semi slam
        if (
          response.data.format === "minislam" ||
          response.data.format === "minislamWS" ||
          response.data.format === "minislamAD" ||
          response.data.format === "semislam"
        ) {
          this.roundAry = response.data.round;
          this.gameAry = response.data.game;
          this.deadlineAry = response.data.deadline;
          this.seedAry = response.data.seed;
          // Set tour group or round
          if (!this.$route.params.divi && this.$route.name === "tourDraw") {
            ApiService.post(
              process.env.VUE_APP_API_URL +
                "&mode=pub&tab=tourframe&action=getdivi&tourid=" +
                this.tour_id,
              {}
            ).then((response) => {
              this.drawTab = response.data;
              this.$router.replace(
                {name: 'tourDrawInfo', params: { tour_id: this.tour_id, divi: response.data }}
              );
            });
          }
        } else if (response.data.format === "buddiescup") {
          // Handle buddies cup
          this.roundAry = response.data.round;
          this.data = response.data.table;
          this.deadJson = response.data.deadJson;
          this.matches = response.data.matches;
          this.penaltyAry = response.data.penalty;
          this.points = response.data.points;
          // Set tour group or round
          if (
            !this.$route.params.divi &&
            this.$route.matched[1].name === "draw"
          ) {
            this.$router.replace(
              {name: 'tourDrawInfo', params: { tour_id: this.tour_id, divi: this.roundAry[0]["group_id"] }}
            );
            this.drawTab = this.roundAry[0]["group_id"];
          }
        } else if (response.data.format === "doublecup") {
          // Handle buddies cup
          this.roundAry = response.data.round;
          this.data = response.data.table;
          this.deadJson = response.data.deadJson;
          this.matches = response.data.matches;
          this.penaltyAry = response.data.penalty;
          // Set tour group or round
          if (
            !this.$route.params.divi &&
            this.$route.matched[1].name === "draw"
          ) {
            this.$router.replace(
              {name: 'tourDrawInfo', params: { tour_id: this.tour_id, divi: this.roundAry[0]["group_id"] }}
            );
            this.drawTab = this.roundAry[0]["group_id"];
          }
        }
      });
    },
  },
});
