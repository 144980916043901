
import { defineComponent,ref } from "vue";
import axios from "axios";
import store from "@/store";
import moment from "moment";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "tourApply",
  components: {
    //Datatable,
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    }
    this.getTourData()
  },
  data() {
    return {
      playerItems: [],
      tourType:'',
      apiMessage:'',
      memo:'',
      mobile:'',
      gender:{},
      area:{},
      mobileError: '',
      partnerUsername: '',
      partnerUsernameError: '',
      genderError: '',
    };
  },
  methods: {
    applyTour() {
      if (!this.mobile) {
        this.mobileError = 'requiredMobile';
        ElMessage.error(this.$t("requiredMobile"));
        (this.$refs.refMobile as any).focus()
      } else if (this.tourType.includes('double') && !this.partnerUsername) {
        this.partnerUsernameError = 'requiredPartnerUsername';
        ElMessage.error(this.$t("requiredPartnerUsername"));
        (this.$refs.refPartnerUsername as any).focus()
      } else if ((this.tourType.startsWith('mens_') && this.gender != 1) || (this.tourType.startsWith('womens_') && this.gender != 2)) {
        this.genderError = 'applyGenderError';
        ElMessage.error(this.$t("applyGenderError"));
        (this.$refs.refGender as any).focus()
      } else {
        axios
          .post(
            process.env.VUE_APP_API_URL + "&mode=jwt&tab=tourapply&action=apply&tourid=" + this.$route.params.tour_id,
            JSON.stringify({mobile: this.mobile, region: this.area, memo: this.memo, type: this.tourType, partner: this.partnerUsername, gender: this.gender})
          )
          .then((response) => {
            if (response.data.message == 'applySuccess') {
              this.getTourData()
            } else if (response.data.message == 'partnerReg') {
              ElMessage.error(this.$t("partnerReg"));
            } else if (response.data.message == 'fakeCantApply') {
              ElMessage.error(this.$t("fakeCantApply"));
            } else {
              ElMessage.error(this.$t(response.data.message));
            }
          });
      }
    },
    getTourData() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=tourapply&action=read&tourid=" + this.$route.params.tour_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          this.apiMessage = response.data.message
          if (response.data.message=='applyStart') {
            this.mobile = response.data.data.mobile
            this.area = Number(response.data.data.region)
            this.tourType = (response.data.data.type)
            this.gender = Number(response.data.data.gender)
          } if (response.data.message=='applied') {
            ElMessage.success(this.$t("appliedTitle"));
            this.$router.push({name: 'tourPay'});
          }
        });
    },
    checkMobile() {
      if (!this.mobile) {
        this.mobileError = 'requiredMobile';
        return false
      } else {
        this.mobileError = '';
        return true
      }
    },
    checkPartner() {
      if (!this.partnerUsername) {
        this.partnerUsernameError = 'requiredPartnerUsername';
        return false
      } else {
        this.partnerUsernameError = '';
        return true
      }
    }

  },
  watch: {
    detectTourid() {
      this.getTourData()
    }
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
    detectTourid() {
      return Number(this.$route.params.tour_id);
    },
    getOptionRegion() {
      return [
        { label: this.$t("region0"), value: 0 },
        { label: this.$t("region1"), value: 1 },
        { label: this.$t("region2"), value: 2 },
        { label: this.$t("region3"), value: 3 },
        { label: this.$t("region4"), value: 4 },
        { label: this.$t("region5"), value: 5 },
        { label: this.$t("region6"), value: 6 },
        { label: this.$t("region7"), value: 7 },
        { label: this.$t("region8"), value: 8 },
        { label: this.$t("region9"), value: 9 },
        { label: this.$t("region10"), value: 10 },
        { label: this.$t("region11"), value: 11 },
        { label: this.$t("region12"), value: 12 },
        { label: this.$t("region13"), value: 13 },
        { label: this.$t("region14"), value: 14 },
        { label: this.$t("region15"), value: 15 },
        { label: this.$t("region16"), value: 16 },
        { label: this.$t("region17"), value: 17 },
        { label: this.$t("region18"), value: 18 },
      ];
    },
    getOptionGender() {
      return [
        { label: this.$t("genderOther"), value: 0 },
        { label: this.$t("genderMale"), value: 1 },
        { label: this.$t("genderFemale"), value: 2 },
      ];
    },
  },
});
