
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import tourInfoContent from "@/views/pages/tour/tourinfo.vue";
import tourDrawContent from "@/views/pages/tour/tourdraw.vue";
import tourApplyContent from "@/views/pages/tour/tourapply.vue";
import tourPayContent from "@/views/pages/tour/tourpay.vue";
import tourPlayersContent from "@/views/pages/tour/tourplayers.vue";

//import { translate } from "element-plus";
//import ApiService from "@/core/services/ApiService";
import axios from "axios";
import { array } from "yup/lib/locale";
//<div class="mt-5" v-if="this.$route.name == 'tourPay'">{{ $t("preparing") }}</div>
export default defineComponent({
  name: "customers-listing",
  components: {
    tourInfoContent, // Import Tour info page
    tourDrawContent, // Import Tour Draw page
    tourApplyContent, // Import Tour Apply page
    tourPayContent, // Import Tour Payment page
    tourPlayersContent, // Import Tour Players page
    
  },
  data() {
    return {
      curTab: "tourInfo", // Default value of the Qtab component
      model: {},
      modelEvent: {},
      options: [{}],
      optionsEvent: [],
      dense: false,
      denseOpts: false,
      optionsLang: [],
      zhTourAry: {},
      oldName: [],
    };
  },
  computed: {
    tour_id() {
      return this.$route.params.tour_id; // Get the dynamic tour_id from url
    },
    event_id() {
      return this.$route.params.event_id; // Get the dynamic tour_id from url
    },
    detectLang(): string {
      return this.$i18n.locale;
    },
  },
  methods: {
    getTourInfo() {
      // Get tour ID if there isn't one
      if (!this.$route.params.tour_id) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "&mode=pub&tab=frametour&action=gettourinfo&sport=" + this.$route.params.sport + "&region=" + this.$route.params.region,
            {}
          )
          .then((response) => {
            if (
              response.data["format"] === "minislam" ||
              response.data["format"] === "semislam"
            ) {
              this.$router.replace({name: 'tourDraw', params: { tour_id: response.data["tour_id"] }}
              );
            } else if (
              response.data["format"] === "buddiescup" ||
              response.data["format"] === "doublecup"
            ) {
              this.$router.replace({name: 'tourDraw', params: { tour_id: response.data["tour_id"] }}
              );
            }
          });
      }
    },
    getTourSelect() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "&mode=pub&tab=frametour&action=tourselect&sport=" + this.$route.params.sport + "&region=" + this.$route.params.region,
          {}
        )
        .then((response) => {
          this.changeSelectLang(response.data);
          this.optionsLang = response.data;
          
        });
    },
    changeSelect(tourID, event_id) {
      if (!tourID) {
        for (let i = 0; i < this.optionsLang['zhAry'].length; i++) {
          if (this.optionsLang['zhAry'][i].event_id == event_id && !tourID) {
            tourID = this.optionsLang['zhAry'][i].tour_id
          }
        }
      }
      if (event_id === "more") {
        this.$router.push({name: 'tourlist', params: { page: 1 }});
      } else if (this.$route.name === "tourInfo") {
        this.$router.push({name: 'tourInfo', params: { event_id: event_id, tour_id: tourID }});
      } else if (this.$route.name === "tourDrawInfo" || this.$route.name === "tourDraw") {
        this.$router.push({name: 'tourDraw', params: { event_id: event_id,tour_id: tourID, divi: null }});
      } else if (this.$route.name === "tourApply") {
        this.$router.push({name: 'tourApply', params: { event_id: event_id,tour_id: tourID }});
      } else if (this.$route.name === "tourPay") {
        this.$router.push({name: 'tourPay', params: { event_id: event_id,tour_id: tourID }});
      } else if (this.$route.name === "tourPlayers") {
        this.$router.push({name: 'tourPlayers', params: { event_id: event_id,tour_id: tourID }});
      }
    },
    getTourName() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "&mode=pub&tab=frametour&action=gettourname&tourid=" +
            this.tour_id +
            "&event_id=" +
            this.event_id +
            "&language=" +
            this.$i18n.locale +
            "&sport=" + this.$route.params.sport + "&region=" + this.$route.params.region,
          {}
        )
        .then((response) => {
          this.oldName = response.data
          if (this.$i18n.locale === "en") {
            this.modelEvent = response.data['eventNameEn']; // Set select tournament name when there isn't in the list
          } else {
            this.modelEvent = response.data['eventNameHk']; // Set select tournament name when there isn't in the list
          }
          this.changeTourType(response.data)
        });
    },
    changeSelectLang(select) {
      if ((this.$route.matched[1].name === "tour" || this.$route.matched[1].name === "draw") && this.$i18n.locale !== null ) {
        this.options = []
        this.model = []
        if (this.$i18n.locale === "en") {
          this.changeTourType(select)
          this.optionsEvent = select.enEventAry;
          this.modelEvent = select.enEventAry[select.enEventAry.findIndex((id) => id.value === this.event_id)];
          if (!this.modelEvent) {
            this.getTourName(); // Set select tournament name when there isn't in the list
          }
        } else if (this.$i18n.locale === "hk"){
          this.changeTourType(select)
          this.optionsEvent = select.zhEventAry;
          this.modelEvent = select.zhEventAry[select.zhEventAry.findIndex((id) => id.value === this.event_id)];
          if (!this.modelEvent) {
            this.getTourName(); // Set select tournament name when there isn't in the list
          }
        }
      }
    },
    changeTourType(select) {
      this.options = []
      if (this.$i18n.locale === "en") {
        for (let i = 0; i < select.enAry.length; i++) {
          if (select.enAry[i].event_id == this.event_id)
          this.options.push(select.enAry[i])
        }
        this.model = select.enAry[select.enAry.findIndex((id) => id.value === this.tour_id)];
      } else if (this.$i18n.locale === "hk"){

        for (let i = 0; i < select.zhAry.length; i++) {
          if (select.zhAry[i].event_id == this.event_id)
          this.options.push(select.zhAry[i])
        }
        this.model = select.zhAry[select.zhAry.findIndex((id) => id.value === this.tour_id)];
      }

    }
  },
  watch: {
    tour_id() {
      if (
        this.$route.matched[1].name === "tour" ||
        this.$route.matched[1].name === "draw"
      ) {
        if(!this.optionsLang['zhEventAry'].some(data => data.event_id === this.event_id)){
          this.changeTourType(this.oldName)
        }else{
          this.changeTourType(this.optionsLang)
        }
        //this.getTourInfo(); // Get tour ID if there isn't one
      }
    },
    detectLang() {
      // Check if lang change
      if (this.optionsEvent.length > 0) {
        this.changeSelectLang(this.optionsLang);
      }
    },
  },
  mounted() {
    this.getTourSelect();
  },
});
