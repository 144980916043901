import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = { class: "mx-3 mx-sm-0 mt-5 mb-3 text-center" }
const _hoisted_3 = {
  key: 0,
  class: "badge badge-light-primary d-none"
}
const _hoisted_4 = {
  key: 1,
  class: "badge badge-light-primary"
}
const _hoisted_5 = {
  key: 2,
  class: "badge badge-light-primary"
}
const _hoisted_6 = {
  key: 3,
  class: "badge badge-light-danger"
}
const _hoisted_7 = {
  key: 4,
  class: "badge badge-light-primary"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.tourContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (new Date(this.tourContent.applystart).getTime() <= Date.now() && new Date(this.tourContent.applyend).getTime() >= Date.now())
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {name: 'tourApply', params: { event_id: this.tourContent.event_id,tour_id: this.tourContent.tour_id }},
                class: "mt-5 btn btn-primary fw-bolder btn-sm fs-5 mx-3 mx-sm-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('applyBtn')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (new Date(this.tourContent.applystart).getTime() <= Date.now() && new Date(this.tourContent.applyend).getTime() >= Date.now())
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("applyStartTitle")), 1))
        : (new Date(this.tourContent.applyend).getTime() <= Date.now() && new Date(this.tourContent.tourstart).getTime() >= Date.now())
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("applyEndtTitle")), 1))
          : (new Date(this.tourContent.tourstart).getTime() <= Date.now() && new Date(this.tourContent.tourend).getTime() >= Date.now())
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("tourStartTitle")), 1))
            : (new Date(this.tourContent.tourend).getTime() <= Date.now())
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("tourEndTitle")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("tourPrepareTitle")), 1))
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.$i18n.locale == 'hk' ? this.tourContent.intro : this.tourContent.intro_en,
      class: "mx-3 mx-sm-0"
    }, null, 8, _hoisted_8),
    (this.tourContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (new Date(this.tourContent.applystart).getTime() <= Date.now() && new Date(this.tourContent.applyend).getTime() >= Date.now())
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {name: 'tourApply', params: { event_id: this.tourContent.event_id,tour_id: this.tourContent.tour_id }},
                class: "mt-5 btn btn-primary fw-bolder btn-sm fs-5"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('applyBtn')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}